<template>
    <v-content>
        <!--<v-breadcrumbs :items="breadCrumb" large></v-breadcrumbs>-->

        <v-snackbar :timeout="callbackResponse.timeout" color="success" top v-model="snackbar">
            {{callbackResponse.message}}
            <v-btn @click="snackbar = false" dark text>Close</v-btn>
        </v-snackbar>

        <v-dialog max-width="500px" v-model="dialogEdit">
            <editForm :editData="editedDataDish"
                      :success="success"
                      @formResponse="onResponse"
                      v-if="dialogEdit" />
        </v-dialog>
        <v-dialog max-width="500px" v-model="dialogDelete">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>
        <v-dialog max-width="500px" v-model="dialogCreate">
            <template v-slot:activator="{ on }">
                <v-btn :success="success" class="ma-4 float-right" color="primary" dark v-on="on">{{$t('add_dish')}}</v-btn>
            </template>
            <createForm :success="success" @formResponse="onResponse" />
        </v-dialog>
        <v-container fluid>
            <!--            <v-expansion-panels hover>-->
            <!--                <v-expansion-panel>-->
            <!--                    <v-expansion-panel-header>Filter</v-expansion-panel-header>-->
            <!--                    <v-expansion-panel-content>-->
            <!--                    </v-expansion-panel-content>-->
            <!--                </v-expansion-panel>-->
            <!--            </v-expansion-panels>-->

            <vue-good-table :columns="columnsDish"
                            :isLoading.sync="isLoading"
                            :pagination-options="{ enabled: true, perPageDropdown: [50, 100,200]}"
                            :rows="DishRows"
                            :totalRows="totalRecords"
                            @on-column-filter="onColumnFilter"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-sort-change="onSortChange"
                            mode="remote">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                        <v-icon @click="editItem(props.row)" class="mr-2" small>edit</v-icon>
                        <v-icon @click="deleteItemRoom(props.row)" small>delete</v-icon>
                    </span>
                </template>
            </vue-good-table>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import createForm from "./CreateDish";
    import editForm from "./EditDish";
    import deleteListData from "@/components/deleteModal";
    import { parseJwt } from "@/mixins/parseJwt";

    export default {
        name: "dish_create_form",
        mixins: [parseJwt],
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadItemsDish();
                },
                deep: true
            }
        },
        components: {
            createForm,
            editForm,
            deleteListData
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                snackbar: false,
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                panel: [0],
                deleteFormData: {},
                popout: true,
                isLoading: false,
                columnsDish: [
                    {
                        label: "s_no",
                        field: "countIndex"
                    },
                    {
                        label: "dish_name",
                        field: "dishName"
                    },
                    {
                        label: "dish_description",
                        field: "dishDescription"
                    },
                    {
                        label: "actions",
                        field: "actions",
                        sortable: false
                    }
                ],
                DishRows: [],
                totalRecords: 0,
                serverParamsDish: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "countIndex",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                breadCrumb: [
                    {
                        text: "Special Dish",
                        to: "/HomeStaySettings",
                        disabled: false,
                        exact: true
                    },
                    {
                        text: "Special Dish",
                        disabled: true
                    }
                ],
                user: [],
                editedIndex: -1,
                editedDataRoom: {}
            };
        },

        mounted() {
            this.loadItemsDish();
            this.filterData();
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            onResponse(data) {
                console.log("ok")
                if (data) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                    this.loadItemsDish();
                } else {
                    this.dialogDelete = false
                }
            },
            onResponseDelete(data) {
                if (data) {
                    this.$emit("formResponseRoomDelete", data);
                    this.dialogDelete = false;
                    this.loadItemsDish();
                } else {
                    this.dialogDelete = false;
                }
            },
            editItem(props) {
                this.editedDataDish = props.homestayDishID;
                this.dialogEdit = true;
                // this.dialogEditFare = true;
            },

            deleteItemRoom(props) {
                console.log(props);
                this.dialogDelete = true;
                this.deleteFormData.url = "Hotel/DeleteHomestaySpecialDishAsync/" + props.homestayDishID;
                this.loadItemsDish();
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            updateParams(newProps) {
                this.serverParamsDish = Object.assign({}, this.serverParamsDish, newProps);
            },

            searchInput() {
                this.loadItemsDish();
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItemsDish();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItemsDish();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItemsDish();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItemsDish();
            },

            async filterData() {
            },
            // load items is what brings back the rows from server
            async loadItemsDish() {

                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParamsDish.perPage,
                    PageNo: this.serverParamsDish.page,
                    OrderType: this.serverParamsDish.sort[0].type,
                    OrderBy: this.serverParamsDish.sort[0].field
                };

                axios.post("Hotel/GetHomestaySpecialDishList", param)
                    .then(response => {
                        this.DishRows = response.data.data;
                        this.totalRecords = response.data.totalCount;

                        console.log('roomrow', this.DishRows);
                        console.log('totalRecords', this.totalRecords);
                    })
                    .catch(err => {
                        console.log(err)
                    });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }
</style>