<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>{{ $t("edit_dish") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <form class="fix-for-nepali-label">
                    <v-text-field v-model="editedData.dishName"
                                  dense
                        outlined>
                        <template v-slot:label>
                            {{ $t("dish_name") }}
                        </template>
                    </v-text-field>
                    <v-text-field v-model="editedData.dishDescription"
                                  :error-messages="dishDescriptionErrors"
                                  required
                                  dense
                        outlined
                                  @input="$v.editedData.dishDescription.$touch()"
                                  @blur="$v.editedData.dishDescription.$touch()">
                        <template v-slot:label>
                            {{ $t("dish_description") }}
                        </template>
                    </v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">
                {{
        $t("cancel")
                }}
            </v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{ $t("save") }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "EditPackageForm",
  props: ["success", "editData"],
  computed: {
    dishNameErrors() {
      const errors = [];
          if (!this.$v.editedData.dishName.$dirty) return errors;
          !this.$v.editedData.dishName.required &&
        errors.push(`${this.$t("dish_name")} ${this.$t("is_required")}`);
      return errors;
    },
    dishDescriptionErrors() {
      const errors = [];
        if (!this.$v.editedData.dishDescription.$dirty) return errors;
        !this.$v.editedData.dishDescription.required &&
        errors.push(`${this.$t("dish_description")} ${this.$t("is_required")}`);
      return errors;
    },
  },
  data() {
    return {
      returnData: [],
      show: false,
      updated: false,
      editedData: {
          dishName: "",
          dishDescription: "",
      },
      servicesData: {
        data: {},
        message: "",
        color: "",
      },
    };
  },
  validations: {
    editedData: {
      dishName: { required },
      dishDescription: { required },
    },
  },
  destroyed() {},
  mounted() {
    this.$forceUpdate();
    this.formData();
  },
  methods: {
    dialogueClose() {
      console.log(this.servicesData);
      this.$emit("formResponse", this.servicesData);
    },
    async formData() {
      const response = await axios.get(
          "Hotel/GetHomestaySpecialDishByIDAsync/" + this.editData
      );
      console.log(response.data);
      this.editedData = response.data;
      this.updated = false;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let param = {
          HomestayDishID: this.editedData.homestayDishID,
          DishName: this.editedData.dishName,
          DishDescription: this.editedData.dishDescription,
        };
        axios
          .post("Hotel/UpdateHomestaySpecialDishAsync", param)
          .then((response) => {
            if (response.data.success) {
              this.updated = true;
              this.editedData = {};
              this.servicesData.data = response.data;
              this.servicesData.message = "Dish Updated Successfully";
              this.servicesData.color = "success";
              this.dialogueClose();
            }
          })
          .catch((response) => {
            this.servicesData.data = response.data;
            this.servicesData.message =
              "Error Adding Service, Please Contact Admin";
            this.servicesData.color = "error";
            this.dialogueClose();
          });
      }
    },
    clear() {
      this.$v.$reset();
      this.editedData.Fare = "";
    },
  },
};
</script>

<style scoped></style>
